<template>
  <div>
    <title-bar :title="$t('navigations.products')" />
    <div v-if="products" class="custom-table">
      <table-actions
        :actions="['search', 'addBtn', 'perPage']"
        :addBtnText="$t('product.add')"
        :addBtnLink="$helper.getAddUrl('product')"
        @onSearchInput="inputChange"
        @perPageChange="perPageChange"
        :defaultPerPage="perPage"
      />
      <table-lite
        :is-slot-mode="true"
        :is-loading="isLoading"
        :columns="columns"
        :rows="products"
        :sortable="{}"
        @is-finished="isLoading = false"
      >
        <template v-slot:name="data">
          <router-link :to="$helper.getEditUrl('product', data.value.id)">{{
            data.value.name
          }}</router-link>
        </template>
        <template v-slot:status="data">
          <b-badge :variant="orderStatusClass(data.value.status)">{{
            data.value.status
          }}</b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn :path="$helper.getEditUrl('product', data.value.id)" />
            <delete-btn @pressDelete="deleteProduct(data.value['@id'])" />
          </div>
        </template>
      </table-lite>
      <table-pagination
        v-if="pages"
        v-model="currentPage"
        :total="pages"
        :per-page="perPage"
        @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import TitleBar from "@/components/TitleBar.vue"

export default {
  components: {
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar
  },
  mixins: [ResourceUtils],
  data() {
    return {
      search: "",
      isLoading: false,
      products: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      columns: [
        {
          label: this.$t("product.name"),
          field: "name",
          sortable: false,
        },
        {
          label: this.$t("product.status"),
          field: "status",
          sortable: false,
          width: "120px",
        },
        {
          label: this.$t("product.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
        },
      ],
    };
  },
  created() {
    this.loadProducts();
  },
  methods: {
    loadProducts() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        'order[createdAt]': 'desc'
      };
      if (this.search && this.search.length) {
        params.search = this.search;
      }
      this.$Products.getCollection({ params }, 'product_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.products = response.data["hydra:member"];
      });
    },
    onChangePage(page) {
      this.currentPage = page;
      this.loadProducts();
    },
    deleteProduct(url) {
      this.deleteByUrl(this.$Products, url, this.$t('product.deleted'), null, this.loadProducts, this.error);
    },
    orderStatusClass(status) {
      let className = "inactive";
      if (status === "ACTIVE") {
        className = "active";
      }
      return className;
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.loadProducts();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.loadProducts();
    },
  },
};
</script>

<style lang="scss">
</style>
